import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "szanse-sprzedazy-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(
      relativePath: { eq: "automatyczna-integracja-crm-z-hm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Integracjacrm = ({ data }) => {
  return (
    <Artykul
      title="Integracja CRM z systemem handlowym"
      keywords={["integracja crm"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Integracja CRM z systemem handlowym jest łatwym sposobem na oszczędność czasu."
      metaTitle="Integracja CRM z systemem handlowym"
      metaDescription="Integracja systemu CRM z programem handlowym ✅ Jak wygląda bezpieczna wymiana danych między CRM i ERP? 
      Jak wygląda proces integracji? Obejrzyj video 2020"
    >
      <p>
        System CRM można zintegrować z oprogramowaniem odpowiedzialnym za handel
        oraz magazyn firmy. W ten sposób uruchamiana jest między nimi wymiana
        danych oraz dokumentów. Integracja CRM z systemem ERP jest prostym
        krokiem do uzyskania jednego, centralnego narzędzia do zarządzania
        przedsiębiorstwem. Dzięki niej pomiędzy systemami przepływają takie
        informacje jak dane dotyczące kontrahentów, produktów czy rozliczeń.
        Wspomaga ona również sam proces wdrożenia, przyspieszając moment startu
        systemu CRM - ponieważ będzie on już miał zaimplementowane najważniejsze
        dane.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/a1S10rsTsb0"
        allowFullScreen
        ratio="16:9"
      />
      <p>
        Integracja CRM z programem handlowo-magazynowym przebiega dwustronnie. Z
        systemu ERP do CRM automatycznie pobierane są dane na temat produktów i
        usług oraz poziomach cenowych i stanach magazynowych. W drugą stronę
        przesyłane są zaś zamówienia. Jako że dzieje się to bez udziału
        użytkownika, oszczędzany jest jego czas oraz eliminowane ryzyko
        popełnienia błędu przy ręcznym przepisywaniu danych.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zintegrować CRM z systemem handlowym?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać integrację z systemem handlowym
               </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>Integracja CRM z systemem handlowym - możliwości</h2>
      <p>
        <ul>
          <li>Wgląd do faktur wygenerowanych w programie handlowym.</li>
          <li>Dostęp do aktualnych stanów magazynowych.</li>
          <li>Dostęp do bieżących rozliczeń z klientami.</li>
          <li>
            Dostęp do indywidualnych cen i rabatów kontrahenta określonych w
            systemie handlowym.
          </li>
          <li>Automatyczny eksport zamówień do programu handlowego.</li>
          <li>Automatyczny import kontrahentów z programu handlowego.</li>
          <li>Automatyczny import produktów i usług z programu handlowego.</li>
          <li>Eksportowanie do ERP danych nowych kontrahentów.</li>
          <li>
            Analizowanie sprzedaży (poprzez faktury) z możliwością podziału
            według kontrahentów, wartości lub innych określonych wcześniej cech
            czy atrybutów.
          </li>
          <li>
            Jeżeli standardowa integracja nie jest wystarczająca, możliwe są
            indywidualne rozszerzenia jej zakresu w ramach dodatkowego pakietu.
          </li>
        </ul>
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={[
          "Integracja CRM z programem handlowo-magazynowym to sposób na automatyzację pracy.",
        ]}
      />
      <br />
      <h2>Integracja CRM z systemem handlowym - zasada działania</h2>
      <br />
      <h3>Baza kontrahentów</h3>
      <p>
        System ERP jest źródłem najważniejszych informacji o kliencie. Pobierane
        są z niego takie dane jak nazwa, adres czy numer identyfikacji
        podatkowej. Przesyłane są również informacje na temat warunków
        współpracy z danym kontrahentem, a więc ustalone rabaty, sposoby
        płatności czy poziomy cenowe.
      </p>

      <p>
        Dodanie w systemie handlowym nowego klienta zaowocuje natychmiastowym,
        automatycznym transferem jego danych do CRM. Natomiast utworzenie nowego
        kontrahenta po stronie systemu do zarządzania relacjami nie uruchomi
        podobnej procedury natychmiast - stanie się to dopiero wraz z pierwszym
        przesłanym zamówieniem.
      </p>
      <br />
      <h3>Dostęp do faktur oraz płatności</h3>
      <p>
        Integracja CRM z systemem handlowo-magazynowym umożliwia przeglądanie
        najważniejszych dokumentów handlowych. Dzięki temu handlowiec będzie
        mógł przeglądać zestawienia faktur, monitorować rozliczenia klientów i
        szybko identyfikować osoby lub firmy zalegające z płatnościami.
      </p>
      <br />
      <h3>Aktualne informacje o produktach i stanach magazynowych</h3>
      <p>
        Ważną, powtarzającą się praktycznie codziennie czynnością każdego
        handlowca jest tworzenie ofert i zamówień. Integracja CRM z systemem
        handlowym to gwarancja tego, że dane wpisane w tych dokumentach będą
        zawsze aktualne. Handlowiec może również sprawdzić obecne stany
        magazynowe oraz grupę cennikową wybranego klienta.
      </p>

      <p>
        W trakcie tworzenia oferty lub zamówienia, system CRM podsunie
        handlowcowi odpowiednią, obowiązującą dla danego klienta cenę. Program
        uwzględni przy tym takie zmienne jak różne poziomy cenowe dla różnych
        grup kontrahentów czy też indywidualne rabaty. Wszystko to dzieje się
        automatycznie, co oszczędza czas pracowników.
      </p>
      <br />
      <h3>Eksport zamówień do programu handlowego</h3>
      <p>
        Integracja CRM z systemem handlowo-magazynowym może również wpłynąć na
        szybkość realizacji zamówień. Jeżeli handlowiec wygeneruje w systemie
        CRM zamówienie, zostanie ono w czasie rzeczywistym przeniesione do
        oprogramowania ERP. Jest to zdecydowanie szybsze od tradycyjnego modelu,
        w którym zebrane za dnia zamówienia handlowiec przesyła do centrali
        wieczorem.
      </p>
      <br />
      <h2>Jak przebiega standardowa integracja?</h2>
      <p>
        Najważniejsze dane oraz dokumenty są przesyłane z systemu handlowego do
        CRM od razu, w czasie rzeczywistym. Dotyczy to rzeczy takich jak nowy
        klient, faktura albo produkt. Tak jak to było wspomniane powyżej,
        natychmiast przesyłane są również zamówienia wygenerowane w CRM.
      </p>

      <p>
        Natomiast mniej istotne zmiany, takie jak poprawiony opis produktu czy
        też poprawki w wystawionych wcześniej dokumentach, przesyłane są do
        systemu CRM w trakcie synchronizacji czasowej, która uruchamia się
        każdego dnia o ustalonej godzinie.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zintegrować CRM z systemem handlowym?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać integrację z systemem handlowym
            </Link>
          </div>
          </div>
    </section>
      <br />
      <br />
      <p>
        <ul>
          <li>
            <Link to="/co-to-jest-crm">Co to jest CRM?</Link>
          </li>
          <li>
            <Link to="/aplikacja-crm-do-pracy-w-terenie">
              Aplikacja CRM: ułatwiona praca w terenie
            </Link>
          </li>
          <li>
            <Link to="/zalety-elastycznego-crm">
              Zalety elastycznego systemu CRM
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Integracjacrm;
